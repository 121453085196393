import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const Seguros = () => {
     const [ramo, setRamo] = useState('vida')    


     useEffect(()=>{
          window.scrollTo(0,0)
     },[])

     const handleRamo = (e)=>{      
          setRamo(e.target.name)
     } 



     return (
          <div className='segurosSite'>
               <div className='headerSeguros'>
               <h5 className='subtitulo'>Contamos con el respaldo las mejores compañias en cada ramo</h5>
               <h1 className='titulo'>Nuestras Soluciones</h1>
          </div>
               <div className='BotonesContainerSeguros'>
                    <button onClick={e => handleRamo(e)} name='vida' className={ramo !== 'vida' ? 'botonSeguros' : 'botonSeguros activo'}>Vida</button>
                    <button onClick={e => handleRamo(e)} name='GMM' className={ramo !== 'GMM' ? 'botonSeguros' : 'botonSeguros activo'}>Gastos Médicos</button>
                    <button onClick={e => handleRamo(e)} name='danos' className={ramo !== 'danos' ? 'botonSeguros' : 'botonSeguros activo'}>Daños</button>
               </div>
               <div className='infoSeguros'>
               {ramo === 'vida' &&
                    <div>           
                         <div className='tituloInfoAseguradora'>
                         <h1 className='tituloSeguros'>Seguros de Vida e Inversión</h1>
                         </div>
                         <div className='infoseguros container'>
                              <h5>¿Qué son los seguros de vida?</h5>
                              <p>
                              La Asociación Mexicana de Instituciones de Seguros (AMIS), define el seguro de vida como un instrumento
                              financiero por el cual se otorga una suma asegurada en caso de fallecimiento, invalidez o supervivencia.      
                              Un seguro de vida está diseñado para darte tranquilidad y ofrecer un respaldo económico a quienes más quieres
                              en caso de imprevistos. Una familia puede tardar más de dos años en recuperar su estabilidad económica tras el 
                              fallecimiento del principal proveedor. Por lo cual, contratar uno significa un gran apoyo y una medida previsoria que
                              no dejará solos a tus seres queridos.
                              </p>
                         </div>
                         <div className='logosAseguradoras container mt-5 mb-5'>
                              <a href='https://www.insignialife.com/' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/insignia.png" alt='insignia' className='logoCard'/>
                              </a>
                              <a href='https://www.gnp.com.mx/seguro-de-vida-consolida-tu-retiro' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/gnp.png" alt='gnp' className='logoCard'/>
                              </a>
                              <a href='https://axa.mx/seguro-vida' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/axxa.png" alt='axa' className='logoCard'/>
                              </a>
                              <a href='https://www.metlife.com.mx/seguros-individuales/seguros-ahorro-retiro/' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/metlife.png" alt='metlife' className='logoCard'/>
                              </a>
                              <a href='https://www.mnyl.com.mx/personas/seguro-de-vida/' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/monterrey.png" alt='monterrey' className='logoCard'/>
                              </a>
                              <a href='https://www.skandia.com.mx/soluciones-para-personas/seguros/Paginas/default.aspx' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/skandia.png" alt='skandia' className='logoCard'/>
                              </a>
                              
                         </div>
                    </div>                  
                    }   


               {ramo === 'GMM' &&
                    <div>           
                         <div className='tituloInfoAseguradora'>
                         <h1 className='tituloSeguros'>GMM Nacionales e Internacionales</h1>
                         </div>
                         <div className='infoseguros container'>
                              <h5>¿Qué son los seguros de Gastos Médicos Mayores</h5>
                              <p>
                              Un  Seguro de Gastos Médicos Mayores es un contrato celebrando con una Institución de Seguros (aseguradora) 
                              que se encargará de  cubrir económicamente los servicios derivados de una atención médica, la cual es resultado
                              de algún accidente o enfermedad. El Seguro de Gastos Médicos Mayores; en algunos casos también se puede considerarse
                              un plan preventivo para cubrir eventualidades en la Salud.
                              </p>
                         </div>
                         <div className='logosAseguradoras container mt-5 mb-5'>
                              <a href='https://axa.mx/seguro-gastos-medicos' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/axxa.png" alt='axa' className='logoCard'/>
                              </a>
                              <a href='https://www.metlife.com.mx/seguros-individuales/salud-gastos-medicos-y-accidentes/' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/metlife.png" alt='metlife' className='logoCard'/>
                              </a>
                              <a href='https://www.gnp.com.mx/contrata-tu-seguro-medico-gnp' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/gnp.png" alt='gnp' className='logoCard'/>
                              </a>
                              <a href='https://www.mnyl.com.mx/personas/seguro-de-salud/' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/monterrey.png" alt='monterrey' className='logoCard'/>
                              </a>
                              <a href='https://www.segurosbanorte.com.mx/gastos-medicos/plan-esencial' target='__blank' rel='nofollow' >
                              <img src="img/aseguradoras/banorte.png" alt='banorte' className='logoCard'/>
                              </a>
                              <a href='https://mexico-health-insurance.com/ALP/R/pg1?mtch=e&kwds=%2Bbupa&cmid=175645390&dgid=10865650750&kwid=kwd-328249690289&dvce=c&crtv=118287515470&adps=&netw=Search&gclid=CjwKCAjw9NeXBhAMEiwAbaY4lvZ5daKI3UMtjcHdIiK1B0xi6G8WbBffMGcQT9vKXQcjZDYbHBKNDBoCgZQQAvD_BwE' target='__blank' rel='nofollow' >
                              <img src="img/aseguradoras/bupa.png" alt='bupa' className='logoCard'/>
                              </a>                      
                         </div>
                    </div>                  
                    }

               {ramo === 'danos' &&
                    <div>           
                         <div className='tituloInfoAseguradora'>
                         <h1 className='tituloSeguros'>Seguros de Daños</h1>
                         </div>
                         <div className='infoseguros container'>
                              <h5>¿Qué son los seguros de Daños</h5>
                              <p>
                              Un  Seguro de Gastos Médicos Mayores es un contrato celebrando con una Institución de Seguros (aseguradora) 
                              que se encargará de  cubrir económicamente los servicios derivados de una atención médica, la cual es resultado
                              de algún accidente o enfermedad. El Seguro de Gastos Médicos Mayores; en algunos casos también se puede considerarse
                              un plan preventivo para cubrir eventualidades en la Salud.
                              </p>
                         </div>
                         <div className='logosAseguradoras container mt-5 mb-5'>
                              <a href='https://www.hdi.com.mx/personas/autos/?utm_source=gsem&utm_medium=sem&utm_campaign=brand&utm_content=auto_brn_dm1&gclid=CjwKCAjw9NeXBhAMEiwAbaY4lpwpw_8KYnxxTIfTZUetb0IzSVm3DVGo75iaa3w9No7aRaLevHhwzBoCYwIQAvD_BwE' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/hdi.png" alt='hdi' className='logoCard'/>
                              </a>  
                              <a href='https://anaseguros.com.mx/anaweb/index.html' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/ana.png" alt='ana' className='logoCard'/>
                              </a>  
                              <a href='https://www.segurosbanorte.com.mx/seguros-hogar/proteccion-hogar' target='__blank' rel='nofollow' >
                                   <img src="img/aseguradoras/banorte.png" alt='banorte' className='logoCard'/>
                              </a>                                        
                         </div>
                    </div>                  
                    }                    
               </div>   
               <Link to='/contacto' className='botonContacto mt-5 mb-5'>¿Necesitas una asesoria?</Link>                      
          </div>
               )}
                    