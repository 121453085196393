import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Paginacion } from './Paginacion'


export const Blog = () => {

     const [categorias, setCategorias] = useState([])
     const [posts, setpost] = useState([])
     const [pagina, setPaginas] = useState(1);

     const cantidadPorPagina = 10;
     const maximo = Math.ceil(posts.length / cantidadPorPagina);



     const params = useParams();





     useEffect(() => {
          getCategorias()
          getPost()
     }, [])

     useEffect(() => {
          filterPOST()
     }, [params])

     // Obtener todas las categorias
     const getCategorias = async () => {

          params && filterPOST()

          const resp = await fetch('https://apiasesores-production.up.railway.app/api/blog/categoras/');
          const data = await resp.json();
          setCategorias(data)
     }

     // Filtro para categorias
     const filterPOST = async () => {
          const resp = await fetch(`https://apiasesores-production.up.railway.app/api/blog/post/?categoria=${params.categoria}`)
          const data = await resp.json();
          setpost(data)
     }



     // Obtener todos los posts
     const getPost = async () => {
          const resp = await fetch('https://apiasesores-production.up.railway.app/api/blog/post/');
          const data = await resp.json();
          setpost(data)
     }





     return (
          <div className='blogSite'>

               <div className='blogTitulo'>
                    <h5 className='subtitulo'>Novedades, noticias y curiosidades del mundo financiero y seguros</h5>
                    <h1 className='titulo'>Blog</h1>
               </div>

               <div className='container mt-5'>
                    <h4 className='tituloblog'>blog</h4>
                    <h5 className='subtituloblog'>Ultimos articulos</h5>
                    <h6 className='subtituloInfo2'>seguros de vida - Seguros GMM - Finanzas </h6>
               </div>

               <div className='blogMain container mt-5'>
                    <div className='contenidoBlog container mt-5'>
                         {posts.slice(parseInt((pagina - 1) * cantidadPorPagina), parseInt(((pagina - 1) * cantidadPorPagina)) + cantidadPorPagina).map(post => {
                              return (
                                   <div className="card cardBlog" key={post.id.toString()} >
                                        <div className="row g-0">
                                             <div className="col-md-4">
                                                  <img src={post.imagenportada} className="img-fluid rounded-start imagenPost" alt={post.titulo} />
                                             </div>
                                             <div className="col-md-8">
                                                  <div className="card-body">
                                                       <Link to={'post/' + post.id}><h5 className="card-title botonNota">{post.titulo}</h5></Link>
                                                       <div dangerouslySetInnerHTML={{ __html: post.contenido.substr(0, 315) + '. . .' }}></div>
                                                       <p className="card-text"><small className="text-muted">Autor: {post.autor.nombre}</small></p>
                                                       <p className='card-text'>{ }</p>
                                                       <p className="card-text"><small className="text-muted">Fecha de publicación: {post.creado.substring(8, 10)}/{post.creado.substring(5, 7)}/{post.creado.substring(0, 4)}</small></p>

                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              )
                         })}

                         <Paginacion maximo={maximo} pagina={pagina} setPagina={setPaginas} />






                    </div>
                    <div className='sidebar'>
                         <h4 className='titulocar'>Categorias</h4>
                         <ul>
                              <li><a href='/blog/' className='btn btn-link itemside'>Todas las Categorias</a></li>
                              {
                                   categorias.map(categoria => {
                                        return (
                                             <li key={categoria.id}>
                                                  <Link to={'/blog/' + categoria.id} className='btn btn-link itemside'>{categoria.nombre}</Link>
                                             </li>
                                        )
                                   })
                              }



                         </ul>

                    </div>

                    <div className="accordion accordion-flush sidebarmovil" id="accordionFlushExample">
                         <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingOne">
                                   <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Categorias
                                   </button>
                              </h2>
                              <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                   <div className="accordion-body">
                                        <ul>
                                             <li><a href='/blog' className='btn btn-link itemside'>Todas las Categorias</a></li>
                                             {
                                                  categorias.map(categoria => {
                                                       return (
                                                            <li>
                                                                 <Link to={'/blog/' + categoria.id} className='btn btn-link itemside'>{categoria.nombre}</Link>
                                                            </li>
                                                       )
                                                  })
                                             }
                                        </ul>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>


          </div >
     )
}
