

export const fecha = () => {


     const dias = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'];
     const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

     const date = new Date()
     const dia = dias[date.getDay()];
     const fecha = date.getDate();
     const mes = meses[date.getMonth()];
     const año = date.getFullYear()

     let mesMoneda = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
     let diaMoneda = date.getDate() + 1 < 10 ? `0${date.getDate()}` : date.getDate()

     let hoy = `${dia} ${fecha} de ${mes} del ${año}`;
     let hoyMoneda = `${año}-${mesMoneda}-${diaMoneda}`;
    

     return {
          hoy,
          hoyMoneda
     }

}
