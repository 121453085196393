import React from 'react';

export const Biografia = () => {
     return (

          <div className='biografia'>
               <div className='contenedorimagesBio'>
                    <img className="imgBiografia" src='./img/aboutbio.png' alt='Biografia ' />
               </div>

               <div className='ContenedorinfoBio' >
                    <h4 className='tituloInfo'>Sobre Nosotros</h4>
                    <h5 className='subtituloInfo'>Somos claros al ofrecerte una solución.</h5>
                    <h6 className='subtituloInfo2'>Estamos contigo en los momentos difíciles.</h6>
                    <p className='resaltado'><strong>Una década de experiencia nos han confirmado que el buen servicio es más importante que la venta.</strong></p>
                    <p className='contenidoBio'>Somos un equipo concentrado en las necesidades más importantes que son la salud, la familia y el servicio,
                         siempre dando opciones a  diferentes necesidades de cada uno de nuestros clientes,
                         buscando la mejor opción para cada persona, negocio o empresa y dando soluciones concretas en precios para cada necesidad.</p>
               </div>
          </div>

     )
};
