import React, { useEffect, useState } from 'react';
import { fecha } from '../utilidades/Fecha';
import {
     Chart as ChartJS,
     CategoryScale,
     LinearScale,
     PointElement,
     LineElement,
     Title,
     Tooltip,
     Legend,
     Filler,
} from "chart.js"
import { Line } from 'react-chartjs-2'

ChartJS.register(
     CategoryScale,
     LinearScale,
     PointElement,
     LineElement,
     Title,
     Tooltip,
     Legend,
     Filler
)


export const Grafico = () => {

     useEffect(() => {
          getHistoricoUDI()
          getHistoricoDolar()

     }, [])

     const { hoyMoneda } = fecha()

     const [historicoUDI, setHistoricoUDI] = useState([]);
     const [historicoDolar, setHistoricoDolar] = useState([]);


     const getHistoricoUDI = async () => {
          const datosUDI = await fetch(`https://www.banxico.org.mx/SieAPIRest/service/v1/series/SP68257/datos/1995-04-04/${hoyMoneda}?token=b5f500c2261f9158a2d41b11c279485b83faf2c0d2b7ce2adb5ce93c8963851d`);
          const { bmx } = await datosUDI.json();
          const serieUdi = bmx.series[0].datos;
          setHistoricoUDI(serieUdi);

     }

     const getHistoricoDolar = async () => {
          const datosDolar = await fetch(`https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/1995-04-04/${hoyMoneda}?token=b5f500c2261f9158a2d41b11c279485b83faf2c0d2b7ce2adb5ce93c8963851d`);
          const { bmx } = await datosDolar.json();
          const serieDolar = bmx.series[0].datos;
          setHistoricoDolar(serieDolar);

     }



     const arregloUDIS = historicoUDI.map((dato) => dato.dato);
     const porcentajesUDI = []
     for (let i = 0; i < arregloUDIS.length; i++) {
          if (arregloUDIS[i] === arregloUDIS[0]) continue
          let percent = (parseFloat(arregloUDIS[i]) / parseFloat(arregloUDIS[0])) - 1
          porcentajesUDI.push(percent)
     }

     const arregloDolar = historicoDolar.map((dato) => dato.dato)
     const porcentajesDolar = []
     for (let i = 0; i < arregloDolar.length; i++) {
          if (arregloDolar[i] === arregloDolar[0]) continue
          let percent = (parseFloat(arregloDolar[i]) / parseFloat(arregloDolar[0])) - 1
          porcentajesDolar.push(percent)
     }

     const arregloAnios = historicoDolar.map((dato) => dato.fecha)
     const rendimientoDolar = (((parseFloat(arregloDolar[arregloDolar.length - 1]) / parseFloat(arregloDolar[0])) - 1) * 100).toFixed(2);
     const rendimientoUDI = (((parseFloat(arregloUDIS[arregloUDIS.length - 1]) / parseFloat(arregloUDIS[0])) - 1) * 100).toFixed(2)

     const labels = arregloAnios

     const options = {
          responsive: true,
          maintainAspectRatio: true,
          interaction: {
               mode: 'index',
               intersect: false,
          },
          stacked: false,
          plugins: {
               title: {
                    display: false,
                    text: 'UDI Vs Dolar',
               },
          },
          scales: {
               y: {
                    type: 'linear',
                    display: true,
                    position: 'left',

               },

          },
     };

     // const labels = arregloAnios


     const data = {
          labels,
          datasets: [{
               label: 'UDI',

               data: porcentajesUDI,
               borderColor: 'rgb(255, 99, 132)',
               backgroundColor: 'rgba(255, 99, 132, 0.5)',
               pointRadius: 0,
               borderWidth: 1.2,
               yAxisID: 'y',
          },
          {
               label: 'Dólar',
               data: porcentajesDolar,
               borderColor: 'rgb(53, 162, 235)',
               backgroundColor: 'rgba(53, 162, 235, 0.5)',
               pointRadius: 0,
               borderWidth: 1.2,
               yAxisID: 'y',
          },
          ]
     }




     return (
          <div className="container mt-5" >
               <h5>UDI Vs Dólar</h5>
               <table className="table">
                    <thead>
                         <tr>
                              <th scope="col">Moneda</th>
                              <th scope="col">1995-04-04</th>
                              <th scope="col">{hoyMoneda}</th>
                              <th scope="col">Crecimiento</th>
                         </tr>
                    </thead>
                    <tbody>
                         <tr>
                              <th scope="row">UDI</th>
                              <td>${typeof arregloUDIS[0] === 'undefined' ? arregloUDIS[0] : arregloUDIS[0].substring(0, 4)}</td>
                              <td>${typeof arregloUDIS[0] === 'undefined' ? arregloUDIS[arregloUDIS.length - 1] : arregloUDIS[arregloUDIS.length - 1].substring(0, 4)}</td>
                              <td>{rendimientoUDI}%</td>
                         </tr>
                         <tr>
                              <th scope="row">DÓLAR</th>
                              <td>${typeof arregloDolar[0] === 'undefined' ? arregloDolar[0] : arregloDolar[0].substring(0, 4)}</td>
                              <td>${typeof arregloDolar[0] === 'undefined' ? arregloDolar[arregloDolar.length - 1] : arregloDolar[arregloDolar.length - 1].substring(0, 5)}</td>
                              <td>{rendimientoDolar}%</td>
                         </tr>
                    </tbody>
               </table>

               <Line options={options} data={data} />
          </div >
     )
}