import React, { useEffect, useState } from 'react';
import { fecha } from '../utilidades/Fecha';
import { Grafico } from '../utilidades/Grafico';



export const Finance = () => {
     const { hoy } = fecha();
     const { hoyMoneda } = fecha();

     const [udi, setudi] = useState('');
     const [dolar, setDolar] = useState('');
     const [inflacion, setInflacion] = useState('');
     const [years, setyears] = useState('')
     const [porcentaje, setporcentaje] = useState(0)
     const [futuro, setfuturo] = useState('')
     // const [uma, setuma] = useState('')




     useEffect(() => {
          getUdi();
          getDolar();
          getInflacion();
     }, [])



     const getUdi = async () => {
          const resp = await fetch(`https://www.banxico.org.mx/SieAPIRest/service/v1/series/SP68257/datos/${hoyMoneda}/${hoyMoneda}?token=b5f500c2261f9158a2d41b11c279485b83faf2c0d2b7ce2adb5ce93c8963851d`);
          const { bmx } = await resp.json();
          const valorUdi = bmx.series[0].datos[0].dato
          setudi(valorUdi)
     }

     const getDolar = async () => {
          const resp = await fetch(`https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/oportuno?token=b5f500c2261f9158a2d41b11c279485b83faf2c0d2b7ce2adb5ce93c8963851d`);
          const { bmx } = await resp.json();
          const valorDolar = bmx.series[0].datos[0].dato
          setDolar(valorDolar)
     }

     const getInflacion = async () => {
          const resp = await fetch(`https://www.banxico.org.mx/SieAPIRest/service/v1/series/SP30578/datos/oportuno?token=b5f500c2261f9158a2d41b11c279485b83faf2c0d2b7ce2adb5ce93c8963851d`);
          const { bmx } = await resp.json();
          const valorInflacion = bmx.series[0].datos[0].dato
          setInflacion(valorInflacion.substring(0, 4));

     }

     const getyears = ({ target }) => {
          const anios = parseFloat(target.value)

          if (anios) {
               setyears(anios)
          } else {
               alert('El campo de años solo puede ser un número natural')
               target.value = ''
          }

     }

     const getPorcentaje = ({ target }) => {
          const porcent = parseFloat(target.value) / 100;

          if (porcent) {

               if (porcent >= 0 && porcent <= 1) {
                    setporcentaje(porcent)
               } else {
                    alert('El campo de porcentaje solo puede ser un número natural entre 1 y 100')
                    target.value = ''
               }
          } else {
               alert('El campo de porcentaje solo puede ser un número natural')
               target.value = ''

          }
     }



     const proyectaUdi = () => {
          let valoractual = parseFloat(udi)
          let valorfuturo = 0

          for (let anio = 1; anio <= years; anio++) {
               valorfuturo = valoractual += valoractual * porcentaje
          }
          setfuturo(valorfuturo)

     }

     // const ImprimirPagina = () => {
     //      console.log(window.print())
     // }









     return (
          <div className='container mt-5'>
               <div className='info-finanzas'>
                    <div className='titulo-div'>
                         <h4>Información Financiera</h4>
                         <small>{hoy}</small>
                    </div>
                    <div className='moneda'>
                         <h6>Dólar</h6>
                         <p>$ {typeof dolar === 'undefined' ? dolar : dolar.substring(0, 5)}</p>
                    </div>
                    <div className='moneda'>
                         <h6>UDI</h6>
                         <p>$ {typeof udi === 'undefined' ? udi : udi.substring(0, 4)}</p>
                    </div>
                    <div className='moneda'>
                         <h6>Inflación</h6>
                         <p>{inflacion}%</p>
                    </div>
                    <div className='moneda'>
                         <h6>UMA Diario</h6>
                         <p>$ 96.22</p>
                    </div>
               </div>
               <div className='contenedor-boton-calculadora'>
                    <h6 className='calculadoraUDI' data-bs-toggle="modal" data-bs-target="#calculadoraUDI">CALCULADORA Proyección de la UDI</h6>


                    <div className="modal fade modalcalculadora" id="calculadoraUDI" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                         <div className="modal-dialog">
                              <div className="modal-content">
                                   <div className="modal-header headerModal">
                                        <h5 className="modal-title" id="exampleModalLabel">CALCULADORA Proyección de la UDI</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                   </div>
                                   <div className="modal-body">
                                        <h6>Proyección de la UDI</h6>


                                        <div className='formularioModal'>

                                             <label className="etiquetaCalculadora" htmlFor="varorUdiHOY">Valor de la UDI</label>
                                             <input className="inputCalculadora" name="varorUdiHOY" type="text" value={udi} disabled></input>

                                             <label className="etiquetaCalculadora" htmlFor="inflacion">Tasa de inflación %</label>
                                             <input onChange={getPorcentaje} className="inputCalculadora" name="inflacion" type="text"></input>

                                             <label className="etiquetaCalculadora" htmlFor="number">Años a proyectar</label>
                                             <input onChange={getyears} className="inputCalculadora" name="years" type="text"></input>

                                             <button onClick={proyectaUdi} type="button" className="btn btn-primary calculaUdi">Calcular</button>

                                             <label className="etiquetaCalculadora" htmlFor="furuto">Valor futuro de la UDI</label>
                                             <input className="inputCalculadora" name="futuro" type="text" value={futuro} disabled></input>
                                        </div>

                                        <div>
                                             <Grafico />
                                        </div>



                                   </div>
                                   <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                        {/* <button onClick={ImprimirPagina} type="button" className="btn btn-primary">Imprimir ejemplo</button> */}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div >
     )
}
