import React from 'react';

export const TarjetaTeam = ({imagen, nombre, puesto, descripcion}) => {
     return (
          <div className='tarjetaTeam'>
               <div className='imagenteamTarjeta'>
                    <img className="imagenpersona" src={imagen} alt='tonio' />
               </div>
               <div className='infoTeamTarjeta'>
                    <h3 className='puesto'>{puesto}</h3>
                    <h2>{nombre}</h2>
                    <p>{descripcion}</p>
               </div>
          </div>);
};
