export const frases = [
     {
          'frase': '“He fallado una y otra vez en mi vida: por eso he conseguido el éxito.”',
          'autor': 'Michael Jordan'
     },
     {
          'frase': '“Lo que haces hoy puede mejorar todos tus mañanas.”',
          'autor': 'Ralph Marston'
     },
     {
          'frase': '“La mejor forma de predecir el futuro es creándolo.”',
          'autor': 'Peter Drucker'
     },
     {
          'frase': '“Tener éxito no es aleatorio, es una variable dependiente del esfuerzo.”',
          'autor': 'Sófocles'
     },
     {
          'frase': '“Cuando pierdas, no olvides la lección.”',
          'autor': 'Dalai Lama'
     },
     {
          'frase': '“No puedes derrotar a la persona que nunca se rinde.” ',
          'autor': 'Babe Ruth'
     },
     {
          'frase': '“Si puedes soñarlo, puedes hacerlo.”',
          'autor': 'Walt Disney'
     },
     {
          'frase': '“Vuelve a intentarlo.Fracasa de nuevo.Fracasa mejor.”',
          'autor': 'Samuel Beckett'
     },
     {
          'frase': '“El miedo es la más grande discapacidad de todas.”',
          'autor': 'Nick Vujcic'
     },
     {
          'frase': '“Las cosas no se dicen, se hacen, porque al hacerlas se dicen solas”',
          'autor': 'Woody Allen'
     },
     {
          'frase': '“Tanto si crees que puedes hacerlo, como si no, en los dos casos tienes razón.”',
          'autor': 'Henry Ford'
     },
     {
          'frase': '“Si no tienes confianza, siempre encontrarás una forma de no ganar.”',
          'autor': 'Carl Lewis'
     },
     {
          'frase': '“Fallarás el 100 % de las cosas que no intentes.”',
          'autor': 'Wayne Douglas Gretzky'
     },
     {
          'frase': '“Si quieres cambiar al mundo, cámbiate a ti mismo.”',
          'autor': 'Mahatma Gandhi'
     }
];