import React from 'react'

export const BarraInfoContact = () => {
     return (
          <>
               <div className="barraInfoContacto">

                    <a href="https://www.google.com.mx/maps/place/Blvd.+Venustiano+Carranza+2931,+La+Salle,+25240+Saltillo,+Coah./@25.448572,-100.9913322,17z/data=!3m1!4b1!4m5!3m4!1s0x86886d504aa9c1f1:0x13b2ea816a484c8f!8m2!3d25.448572!4d-100.9891382"
                         target="_blank"
                         rel="noreferrer"
                    >
                         <i className="fas fa-home"></i> Blvd. Venustiano Carranza 2931, Saltillo, Coahuila
                    </a>

                    <a href="https://api.whatsapp.com/send?phone=528444527740&text=Hola,%20Necesito%20más%20información%20sobre%20seguros%20por%20favor." target="_blank" rel="noreferrer">
                         <i className="fas fa-mobile-alt"></i> (844) 4527740
                    </a>
                    <a href="tel:8444168088">
                         <i className="fas fa-phone"></i> (844)-416-8088
                    </a>

                    <a href="mailto:info@eichmann-asesores.com">
                         <i className="fas fa-envelope"></i> info@eichmann-asesores.com
                    </a>


                    <ul className="redesBarra">
                         <li>
                              <a href="https://www.facebook.com/Eichelmann-asesores-100540525832691" target="_blank" rel="noreferrer"><i className="fab fa-facebook-square"></i></a>
                         </li>
                         <li>
                              <a href="https://www.instagram.com/eichelmann_asesores/"><i className="fab fa-instagram"></i></a>
                         </li>
                         <li>
                              <a href="/"><i className="fab fa-twitter-square"></i></a>
                         </li>
                         <li>
                              <a href="/"><i className="fab fa-youtube-square"></i></a>
                         </li>
                    </ul>

               </div>

               <div className="barraInfoContactoMovil">

                    <a href="https://www.google.com.mx/maps/place/Blvd.+Venustiano+Carranza+2931,+La+Salle,+25240+Saltillo,+Coah./@25.448572,-100.9913322,17z/data=!3m1!4b1!4m5!3m4!1s0x86886d504aa9c1f1:0x13b2ea816a484c8f!8m2!3d25.448572!4d-100.9891382"
                         target="_blank"
                         rel="noreferrer"
                    >
                         <i className="fas fa-map-marked"></i>
                    </a>

                    <a href="tel:8444527740">
                         <i className="fas fa-phone"></i>
                    </a>

                    <a href="mailto:info@eichmann-asesores.com">
                         <i className="fas fa-envelope"></i>
                    </a>

               </div>

          </>
     )
}
