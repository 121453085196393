import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const Contacto = () => {

     useEffect(()=>{
          window.scrollTo(0,0)
     },[])

     const datosInicial = {
          nombres: "",
          apellidos: "",
          telfono: "",
          correo: "",
          asunto: "",
          seguro: "",
          observaciones: "",

     }





     const MySwal = withReactContent(Swal)

     const [datosCorreo, setdatosCorreo] = useState(datosInicial);

     const handleInputChange = ({ target }) => {
          setdatosCorreo({ ...datosCorreo, [target.name]: target.value })
     }

     const url = 'https://apiasesores-production.up.railway.app/api/clientes/clientes/'

     const enviarCorreo = async (e) => {
          e.preventDefault()
          const nuevoCliente = await fetch(url, {
               method: 'POST',
               headers: {
                    'Content-type': 'application/json'
               },
               body: JSON.stringify(datosCorreo)
          })


          MySwal.fire({
               title: <h5>Correo enviado con éxito</h5>,
               html: <p>{datosCorreo.nombre} Uno de nuestros agentes se comunicará con usted a la brevedad posible mediante {datosCorreo.telefono} o bien
                    vía email a {datosCorreo.correo}</p>,
               footer: <small>Gracias por comunicarse con nosotros</small>,
               icon: 'success'
          })


     }




     return (
          <>
               <div className='ContactoTitulo'>
                    <h5 className='subtitulo'>Estamos para aclarar todas tus dudas.</h5>
                    <h1 className='titulo'>CONTACTANOS</h1>
               </div>
               <h2>Comunícate con nosotros</h2>
               <div className='contactoHoja'>
                    <div className='formulario_contacto'>

                         <form onSubmit={enviarCorreo} className='formularioContacto'>

                              <input onChange={handleInputChange} autoComplete='' name="nombres" type="text" placeholder="Nombres*" required className="inputTexto" />
                              <input onChange={handleInputChange} autoComplete='' type="text" placeholder="Apellidos*" name="apellidos" required className="inputTexto" />
                              <input onChange={handleInputChange} autoComplete='' type="email" placeholder="Email*" name="correo" required className="inputTexto" />
                              <input onChange={handleInputChange} autoComplete='' type="text" placeholder="Telefóno*" name="telfono" required className="inputTexto" />
                              <input onChange={handleInputChange} autoComplete='' type="text" placeholder="Asunto*" name="asunto" required className="inputTexto" />
                              <select onChange={handleInputChange} className='selector' name="seguro" defaultValue={""}>
                                   <option disabled className='disabled' value="">-- Seguro de --</option>
                                   <option value="Vida">Vida y ahorro</option>
                                   <option value="GMM">Gastos Médicos</option>
                                   <option value="Daños">Daños</option>
                              </select>
                              <textarea onChange={handleInputChange} className='textoarea' name="observaciones" placeholder='Dejanos tu comentario'></textarea>
                              <button className='enviar'>Enviar</button>
                         </form>
                    </div>
                    <div className='datosContacto'>

                         <ul className='datosContactoList'>
                              <li className='itemListContacto'><i className="fas fa-phone-square-alt colorIcono"></i> Oficina:  (844)-416-8088 </li>
                              <li className='itemListContacto'><i className="fab fa-whatsapp-square colorIcono"></i> Whatsapp: (844) 452 7740 </li>
                              <li className='itemListContacto'><i className="fas fa-envelope-square colorIcono"></i> Correo: info@eichmann-asesores.com</li>
                              <li className='itemListContacto'><i className="fas fa-map-marker-alt colorIcono"></i> Direccion: Blvd. Venustiano Carranza 2931, Saltillo, Coahuila</li>
                         </ul>
                         <iframe className='mapa' title="eichelmann-asesores" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.6906068917388!2d-100.99138908456447!3d25.44860532769881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86886d504aa9c1f1%3A0xe402d65a813dd5b7!2sEichelmann!5e0!3m2!1ses-419!2smx!4v1643910542585!5m2!1ses-419!2smx"
                              style={{ border: 0 }} loading="lazy"></iframe>
                    </div>
               </div>
          </>
     )
}
