import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'


export const BlogDetail = () => {

     useEffect(() => {
          getPost()
     }, [])

     const params = useParams()


     const [post, setPost] = useState({
          id: '',
          titulo: '',
          imagen: '',
          imagenportada: '',
          contenido: '',
          creado: '',
          activo: '',
          autor: {
               id: '',
               nombre: '',
               activo: ''
          },
          categorias: [

          ]


     })

     const getPost = async () => {
          const resp = await fetch(`https://apiasesores-production.up.railway.app/api/blog/post/${params.id}/`);
          const data = await resp.json()
          setPost(data)
     }







     return (
          <>
               <div className='container'>
                    <div className='contImagenPost'>
                         <img className='imagenPrincipalPost' src={post.imagen} alt={post.titulo} />
                    </div>
                    <div className='contenedorContenidoPost'>
                         <div className='contenedorTitulo'>
                              <h1 className='tituloPost'>{post.titulo}</h1>
                         </div>
                         <div className='contnidoPost'>
                              <div dangerouslySetInnerHTML={{ __html: post.contenido }}></div>
                         </div>
                         <div className='footerPost'>
                              <hr />
                              <p>Creado por: <span className="autoPost">{post.autor.nombre}</span></p>
                              <div className='categoriasPost'>
                                   <p>Categorias:
                                        {post.categorias.map(categoria => {
                                             return <span>  {categoria.nombre}</span>
                                        })}
                                   </p>
                              </div>
                         </div>


                    </div>
               </div>
          </>
     )
}
