import React from 'react';
import { Route, Routes } from 'react-router';
import { AbousScreen } from '../components/about/AbousScreen';
import { HomeScreen } from '../components/home/HomeScreen';
import { Navbar } from '../components/ui/Navbar';
import { BarraInfoContact } from '../components/ui/BarraInfoContact';
import { Link } from 'react-router-dom';
import { handleUp } from '../components/eventos/eventos';
import { Seguros } from '../components/Seguros/Seguros';
import { Contacto } from '../components/Contacto/Contacto';
import { Blog } from '../components/Blog/Blog';
import { PieDePagina } from '../components/home/PieDePagina';
import { BlogDetail } from '../components/Blog/BlogDetail';


export const AppRouter = () => {
     return (
          <>

               <div className="App">
                    <BarraInfoContact />
                    <Navbar />
                    <div className="botonera">
                         <div onClick={handleUp} className='boton-volverarriba'><Link className="linkBtnBotonera" to="/"><i className="fas fa-chevron-up"></i></Link></div>
                         <div className='boton-whatsapp'>
                              <a className="linkBtnBotonera"
                                   href="https://wa.me/8180968180"
                                   target="_blank"
                                   rel="noreferrer">
                                   <i
                                        className="fab fa-whatsapp"></i>
                              </a>
                         </div>
                         <div className='boton-instagram'><a className="linkBtnBotonera" href="https://www.instagram.com/eichelmann_asesores/"><i className="fab fa-instagram"></i></a></div>
                         <div className='boton-facebook'><a className="linkBtnBotonera" href="https://www.facebook.com/Eichelmann-asesores-100540525832691"><i className="fab fa-facebook-f"></i></a></div>
                    </div>
                    <Routes>
                         <Route path="/" element={<HomeScreen />} />
                         <Route path="/about" element={<AbousScreen />} />
                         <Route path="/Seguros" element={<Seguros />} />
                         <Route path="/contacto" element={<Contacto />} />
                         <Route path="/Blog/:categoria/" element={<Blog />} />
                         <Route path="/Blog/:categoria/post/:id" element={<BlogDetail />} />
                         <Route path="/Blog" element={<Blog />} />
                         <Route path="/Blog/post/:id" element={<BlogDetail />} />

                    </Routes>
               </div>
               <PieDePagina />

          </>
     )
}
