import React from 'react'
import { frases } from '../datosVarios/frases'

export const Atencion = () => {

     let nro_frase = Math.round(Math.random() * (frases.length - 1));
     const { frase, autor } = frases[nro_frase];

     return (
          <>

               <h2 className='text-center mt-5'>Servicios</h2>

               <div className="card-container">
                    <div className="flip-card">
                         <div className="front-card">
                              <p><i className="fas fa-hands-helping"></i></p>
                              <h4>Estamos contigo para acompañarte.</h4>
                              <div className="linea-card"></div>
                         </div>

                         <div className="back-card">
                              <h3><i className="fas fa-balance-scale"></i></h3>
                              <h3>Somos enlace entre nuestro cliente y la compañía aseguradora.</h3>
                              <p>Las pruebas duras de los seguros son los siniestros y cuando ocurren
                                   estamos contigo para acompañarte a solucionarlos.</p>
                         </div>
                    </div>

                    <div className="flip-card">
                         <div className="front-card">
                              <p><i className="fas fa-headset"></i></p>
                              <h4>Disponibles 24/7.</h4>
                              <div className="linea-card"></div>
                         </div>

                         <div className="back-card">
                              <h3><i className="fas fa-headset"></i></h3>
                              <h3>Nos mantenemos atentos a tus necesidades en todo momento.</h3>
                              <p>Disponibles 24/7 y solidarios ante las eventualidades que atraviesan
                                   en el momento de un siniestro.</p>
                         </div>
                    </div>

                    <div className="flip-card">
                         <div className="front-card">
                              <p><i className="fas fa-hand-holding"></i></p>
                              <h4>Estamos ahí para ayudarte.</h4>
                              <div className="linea-card"></div>
                         </div>

                         <div className="back-card">
                              <h3><i className="fas fa-hand-holding"></i></h3>
                              <h3>Facilitamos las cosas en los momentos más complicados.</h3>
                              <p>Un siniestro en ocasiones suele sobrepasar nuestras capacidades,
                                   estamos ahí para ayudarte a resolver los desafíos del momento.</p>
                         </div>
                    </div>
               </div>
               <figure className="text-center mt-5 mb-5">
                    <blockquote className="blockquote">
                         <p>{frase}</p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                         <cite title="Source Title">{autor}</cite>
                    </figcaption>
               </figure>
          </>
     )
}
