import React from 'react'
import { Link } from 'react-router-dom'

export const EquipoHome = () => {
     return (
          <div className='EquipoHome'>
               <div className='textoEquipo'>
                    <h1 className='tituloEquipo'>¿Por qué nos pefieren?</h1>
                    <p><i className="far fa-heart"></i></p>
                    <p>En Eichelmann aseesores nos ocupamos a conciencia de las necesidades de nuestros clientes que terminamos siendo buenos amigos,
                         además de que la transparencia es nuestra carta de presentación, explicamos claramente los entramados de la aseguradora, tabuladores, condiciones, cobertura en red.
                         Siempre estaremos a tu lado resolvemos problemas y contratiempos nosotros mismos, facilitando los tramites en los momentos más difíciles.
                    </p>

               </div>
               <Link className='aboutEquipo' to="/about">Conocenos más</Link>


          </div>
     )
}
