import React from 'react'

export const TituloAbout = () => {
     return (
          <div className='tituloAbout'>
               <h5 className='subtitulo'>Bienvenidos a Eichemann Asesores</h5>
               <h1 className='titulo'>SOBRE NOSOTROS</h1>
          </div>
     )
}
