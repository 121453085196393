import React, { useState } from 'react'



export const Paginacion = ({ pagina, setPagina, maximo }) => {

     const [input, setinput] = useState(1);

     const nextPage = () => {
          setinput(parseInt(input) + 1);
          setPagina(parseInt(pagina) + 1);
     }

     const previousPage = () => {
          setinput(parseInt(input) - 1);
          setPagina(parseInt(pagina) - 1);
     }

     const seleccionPaginaManual = (e) => {
          if (e.keyCode === 13) {
               setPagina(parseInt(e.target.value))

               if (parseInt(e.target.value) < 1 ||
                    parseInt(e.target.value) > maximo ||
                    isNaN(parseInt(e.target.value))) {
                    setPagina(1);
                    setinput("1")
               } else {
                    setPagina(parseInt(e.target.value))
               }
          }


     };

     const cambioInput = (e) => {
          setinput(e.target.value)
     };



     return (
          <div className='mt-5 containerPaginacion'>
               <button className='btn btn-dark' onClick={previousPage} disabled={pagina <= 1}>{"<"}</button>
               <input onChange={(e) => cambioInput(e)} onKeyDown={(e) => seleccionPaginaManual(e)} value={input} type="text" />
               <p>DE {maximo}</p>
               <button className='btn btn-dark' onClick={nextPage} disabled={pagina >= maximo}>{">"}</button>
          </div>
     )
}
