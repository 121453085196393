import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const PieDePagina = () => {

     const correoinicial = { 'correo': '' }
     const MySwal = withReactContent(Swal)

     const [correo, setcorreo] = useState(correoinicial)
     const handleInputChange = ({ target }) => {
          setcorreo({ [target.name]: target.value })
     }

     const handlePost = async (e) => {
          e.preventDefault()

          const registro_newsletter = await fetch('https://apiasesores-production.up.railway.app/api/clientes/newsletter/', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json'
               },
               body: JSON.stringify(correo)

          })


          MySwal.fire({
               title: <h5>Te has registrado a nuestro newsletter</h5>,
               html: <p>De parte de todo el equipo de Eichelmann Asesores te damos la bienvenida a nuestro boletín de noticias</p>,
               footer: <small>Gracias!!!</small>,
               icon: 'success'
          })

     }




     return (
          <footer className="footer-container mt-3">
               <div className='footerSeccion1'>
                    <div className='newsletterContainer'>
                         <h4 className='titleNewsletter'>Para más noticias e información sobre nuevos productos</h4>
                         <h3 className='suscribeteAqui'>Suscríbete AQUÍ</h3>
                         <form className='formulario' onSubmit={handlePost}>
                              <input onChange={handleInputChange} name="correo" type="email" placeholder="correo@email.com" className="inputForm" />
                              <button type='submit' className="btnForm"><i className="far fa-paper-plane"></i></button>
                         </form>
                    </div>
                    <div className='logoFooter'>
                         <img className="logoOla" src='./img/logoOlas.png' alt='logo' />
                    </div>

               </div>

               {/* SECCION DE MENU */}

               <div className='footerSeccion2'>

                    <div className='infoFooter'>
                         <h2>Eichelmann Asesores</h2>
                         <p>
                              Nos ocupamos a conciencia de las necesidades de nuestros clientes que terminamos haciendo relaciones duraderas, sobrepasando el servicio de calquier agente.
                         </p>
                    </div>
                    <div className='menuFooter'>
                         <h5 className='subtituloMenuFooter'>Mapa del sitio</h5>
                         <ul className='listMenuFooter'>
                              <li className='itemMenuFooter' >
                                   <Link className='linkMenuFooter' to="/">Inicio</Link>
                              </li>
                              <li className='itemMenuFooter' >
                                   <Link className='linkMenuFooter' to="/about">Sobre Nosotros</Link>
                              </li>
                              <li className='itemMenuFooter' >
                                   <Link className='linkMenuFooter' to="/Seguros">Seguros</Link>
                              </li>
                              <li className='itemMenuFooter' >
                                   <Link className='linkMenuFooter' to="/Blog">Blog</Link>
                              </li>
                              <li className='itemMenuFooter' >
                                   <Link className='linkMenuFooter' to="/contacto">Contacto</Link>
                              </li>
                         </ul>
                    </div>

                    <div className='menuFooter'>
                         <h5 className='subtituloMenuFooter'>Términos y condiciones</h5>
                         <ul className='listMenuFooter'>
                              <li className='itemMenuFooter' >
                                   <Link className='linkMenuFooter' to="#">Políticas de privacidad</Link>
                              </li>
                         </ul>
                    </div>

                    <div className='menuFooter'>
                         <h5 className='subtituloMenuFooter'>Estamos siempre para ti...</h5>
                         <ul className='listMenuFooter'>
                              <li className='itemMenuFooter' >
                                   <a className='linkMenuFooter' href="tel:8444527740" ><i className="fas fa-mobile-alt"></i> 844-452-7740</a>
                              </li>
                              <li className='itemMenuFooter' >
                                   <a className='linkMenuFooter' href="tel:8180968180" ><i className="fas fa-mobile-alt"></i> 818-096-8180</a>
                              </li>
                              <li className='itemMenuFooter' >
                                   <a className='linkMenuFooter' href="tel:8444168088" ><i className="fas fa-phone-alt"></i> 844-416-8088</a>
                              </li>
                              <li className='itemMenuFooter' >
                                   <a className='linkMenuFooter' href="mailto:info@eichelmann-asesores.com" ><i className="fas fa-at"></i> info@eichelmann-asesores.com</a>
                              </li>
                              <li className='itemMenuFooter' >
                                   <a className='linkMenuFooter' href="https://www.facebook.com/Eichelmann-asesores-100540525832691"
                                        target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i> Facebook</a>
                              </li>

                         </ul>
                    </div>
               </div>


          </footer >
     )
}
