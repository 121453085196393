import React from 'react';
import { Atencion } from './Atencion';
import { CarrouselMarcas } from './CarrouselMarcas';
import { EquipoHome } from './EquipoHome';
import { Finance } from './Finance';
import { SliderMain } from './SliderMain';


export const HomeScreen = () => {

     return (
          <div>
               <SliderMain />
               <Finance />
               <Atencion />
               <EquipoHome />
               <CarrouselMarcas />
          </div>
     )
}
