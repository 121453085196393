import React, { useState, useEffect } from 'react';
import { TarjetaTeam } from './TarjetaTeam';

export const Team = () => {

     useEffect(() => {
          getEquipo()
     }, []);

     const [equipo, setequipo] = useState([]);

     const getEquipo = async () => {
          const peticion = await fetch('https://apiasesores-production.up.railway.app/api/equipo/empleados/');
          const resp = await peticion.json()
          setequipo(resp)
     };

     return (
          <div className='Team'>
               <h4 className='tituloTeam'>Expertos en servicio</h4>
               <h2 className='subtituloTeam'>NUESTRO EQUIPO</h2>
               <div className='equipo'>
                    {equipo.map(persona => {
                         return <TarjetaTeam nombre={persona.nombre} imagen={persona.imagen} puesto={persona.puesto} descripcion={persona.descripcion} />
                    })}
               </div>
          </div>
     )
};
