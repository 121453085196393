import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { marcas } from '../datosVarios/marcas';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';






export const CarrouselMarcas = () => {

     useEffect(() => {
          getMarcas()
     }, []);

     const [marcas, setmarcas] = useState([])

     const getMarcas = async () => {
          const resp = fetch('https://apiasesores-production.up.railway.app/api/imagenes/logos/');
          const data = await (await resp).json();
          setmarcas(data);
     }

     const items = marcas.map(marca => {
          return <div className='marca' key={marca.id}>
               <img className="logoMarca" src={marca.url} alt={marca.marca} />
          </div>
     })





     return (
          <div>

               <div className='Contenedor-Principal'>
                    <div className='Carousel mb-5'>
                         <AliceCarousel items={items}
                              responsive={{ 1200: { items: 5 } }}
                              infinite={true}
                              autoPlay={true}
                              animationDuration={5000}
                              autoPlayInterval={0}
                              animationEasingFunction="linear"
                              disableButtonsControls={true}
                              disableDotsControls={true} />
                    </div>
                    <Link className="btn-seguro" to="/seguros">Encuentra AQUÍ tu seguro ideal</Link>
               </div>

          </div>
     )
}
