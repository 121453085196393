import React from 'react'
import { Biografia } from './Biografia'
import { Team } from './Team'
import { TituloAbout } from './TituloAbout'

export const AbousScreen = () => {
     return (
          <>
               <TituloAbout />
               <Biografia />
               <Team />
          </>
     )
}
