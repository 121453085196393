import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';


export const Navbar = () => {

     const [menuActivo, setmenuActivo] = useState(false)
     const handleMenuMovil = () => {
          return setmenuActivo(!menuActivo)
     }






     return (
          <nav className="navMenu">
               <div className="logoContainer">
                    <Link to="/"><img className="logo" src="./img/logo-horizontal.png" alt="logo Eichelmann Asesores" /></Link>
               </div>

               <div className={menuActivo ? "containerlistMenu-activado" : "containerlistMenu"}>
                    <ul className="listMenu">
                         <li className="menuItem">
                              <NavLink className="linkMenu" to="/">Inicio</NavLink>
                         </li>
                         <li className="menuItem">
                              <NavLink className="linkMenu" to="/about">Nosotros</NavLink>
                         </li>
                         <li className="menuItem">
                              <NavLink className="linkMenu" to="/seguros">Seguros</NavLink>
                         </li>
                         <li className="menuItem">
                              <NavLink className="linkMenu" to="/blog">Blog</NavLink>
                         </li>
                         <li className="menuItem">
                              <NavLink className="linkMenu" to="/contacto">Contacto</NavLink>
                         </li>
                    </ul>
               </div>

               <button className="menuMovil mb-2" onClick={handleMenuMovil}>
                    {menuActivo ? <i className="fas fa-times "></i> : <i className="fas fa-bars"></i>}
               </button>



          </nav>
     )
}
