import React from 'react'

export const Carga = () => {
     return (
          <div className='loading'>
               <div className='logoFooter loadingLogo'>
                    <img className="logoOla" src='./img/logoOlas.png' alt='logo' />
                    <p>Eichelmann Asesores</p>
                    <small>cargando...</small>
               </div>
          </div>
     )
}
