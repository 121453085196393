
import React, { useEffect, useState } from 'react'
import { Carga } from './Carga';

export const SliderMain = () => {


     useEffect(() => {
          getBanners()

     }, []);


     const [Banners, setBanners] = useState([]);
     const [loading, setloading] = useState(true)




     const getBanners = async () => {
          const resp = await fetch('https://apiasesores-production.up.railway.app/api/imagenes/banners/');
          const data = await resp.json();
          setBanners(data);
          setloading(false)
     }





     return (
          <>

               {loading && <Carga />}



               <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">

                    <div className="carousel-inner">

                         {Banners.map(banner => {
                              return (
                                   <div className={banner.id === 1 ? 'carousel-item active' : 'carousel-item'} key={banner.id}>
                                        <img src={banner.url} className="d-block sliderImage w-100" alt={banner.titulo} />
                                   </div>
                              )
                         })}

                         {/* <div className="carousel-item active">
                              <img src="./img/gmm.jpg" className="d-block sliderImage w-100" alt="..." />
                         </div>
                         <div className="carousel-item">
                              <img src="./img/danos.jpg" className="d-block sliderImage w-100" alt="..." />
                         </div>
                         <div className="carousel-item">
                              <img src="./img/vida.jpg" className="d-block sliderImage w-100" alt="..." />
                         </div> */}
                    </div>

                    <div className="spinnerBanner">
                         <img className="spinnerImage" src="./img/spinner.png" alt="spinner Eichelmann Asesores" />
                    </div>

                    <div className="playBanner">
                         <button className="playBotton"><i className="fas fa-play"></i></button>
                    </div>




                    <div className="textoBanner">
                         <h6>
                              ASEGURA TU VIDA EN VIDA...
                         </h6>
                         <h2>
                         Estamos contigo en los momentos difíciles.
                         </h2>
                         <p>
                              Para cada miembro del equipo Eichelmann es de suma importancia construir relaciones perdurables con nuestros clientes.
                              Una década de experiencia nos han confirmado que el buen servicio es más importante que la venta.
                         </p>
                         <a href="https://api.whatsapp.com/send?phone=528444527740&text=Hola,%20Necesito%20agendar%20una%20asesoria%20por%20favor." target="_blank" rel="noreferrer"className="btnSpinner"><i className="far fa-calendar-alt"></i> Haz una cita</a>
                    </div>
               </div>

               <div className="modal-container">
                    <div className="contenidoModal cerrarModal">
                         <button className="btn-modal"><i className="fas fa-times"></i></button>
                         <iframe
                              className="videoModal"
                              src="https://www.youtube.com/embed/BCQ1f0oGmm8"
                              title="YouTube video player" frameBorder="0"
                              allow="accelerometer; autoplay ; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen></iframe>
                    </div>
               </div>
          </>


     )
}


